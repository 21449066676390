/* ibm-plex-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/ibm-plex-sans-v19-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* ibm-plex-sans-italic - latin */
@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/ibm-plex-sans-v19-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* ibm-plex-sans-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/ibm-plex-sans-v19-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* ibm-plex-sans-700italic - latin */
@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/ibm-plex-sans-v19-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

$font-family-sans-serif: "IBM Plex Sans", system-ui, -apple-system, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 1.125rem;
