.entrylist {
  &::before {
    position: absolute;
    top: calc((1.32188rem * 1.575) + 0.8625vw); // helps center dot for responsive font sizes
    bottom: 0;
    left: 1.1rem;
    display: block;
    width: 1px;
    content: "";
    background: linear-gradient(to top, rgba($gray-900, 0) 0%, rgba($gray-900, 1) 6.25rem, rgba($gray-900, 1) 100%,);
  }

  @media (min-width: 1200px) {
    &::before {
      top: 2.6265625rem;
    }
  }

  &__item {
    &::before {
      position: absolute;
      top: calc((1.32188rem * 1.575) + 0.8625vw); // helps center dot for responsive font sizes
      left: 0;
      display: block;
      width: 0.625rem;
      height: 0.625rem;
      content: "";
      background-color: $primary;
      border-radius: 50%;
      transform: translate(-50%, 0);
    }

    @media (min-width: 1200px) {
      &::before {
        top: 2.6265625rem;
      }
    }
  }
}
