/* stylelint-disable color-no-hex */

$white: #ffffff;
$gray-700: #495057;
$gray-900: #212529;

$primary: #d42274;
$body-bg: #fbfafa;

/* stylelint enable */

$navbar-light-color: $gray-900;
$navbar-light-hover-color: $gray-700;
$navbar-light-active-color: $primary;

$carousel-control-color: $primary;
