@use "sass:math";

$spacer: $font-size-base;
$spacers: (
  0: 0,
  1: math.div($spacer, 4),
  2: math.div($spacer, 2),
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2.25,
  6: $spacer * 3,
);

$modal-fade-transform: scale(0.8);

body {
  padding-top: 4.375rem;
}

.navbar {
  background-color: rgba($white, 0.98);
}

.banner-image {
  object-fit: cover;
  width: 100%;
  min-height: 45vh;
  max-height: 45vh;
  filter: blur(0.3125rem) grayscale(100%) opacity(30%);
}

blockquote {
  padding-left: map-get($spacers, 3);
  margin: map-get($spacers, 3) 0;
  border-left: map-get($spacers, 2) solid $primary;
}

.card-img-top {
  filter: grayscale(100%);
}

.hero-image {
  min-height: 25vh;
  max-height: 34vh;
}
